import { Button, Divider, Stack } from '@landler/tw-component-library';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';

import { useIsLandPortionProject } from '../../hooks/useIsLandPortionProject';
import { useScenarioForm } from '../../hooks/useScenarioForm';
import { EditSettings } from './components/EditSettings';
import { LabelFilters } from './components/LabelFilters';

export const ScenarioEditor = () => {
  const { t } = useTranslation();
  const [isEditingSettings, setIsEditingSettings] = useState(false);
  return (
    <Stack
      data-testid='scenario-editor'
      spacing={6}
      className='whitespace-pre-wrap rounded-2xl bg-white-100 p-6 text-text-secondary'
    >
      <Stack className='typography-overline font-medium text-black-100'>
        {t('shared.projects.project.impactProjections.scenarioEditor.title')}
      </Stack>
      <Stack className='typography-body1 flex-col justify-between md:flex-row'>
        <ScenarioInfoLabel />
        <Button variant='outline' className='border-primary-100 text-info' onClick={() => setIsEditingSettings(true)}>
          {t('shared.projects.project.impactProjections.scenarioEditor.editSettings.title')}
        </Button>
        <EditSettings open={isEditingSettings} onOpenChange={(open) => setIsEditingSettings(open)} />
      </Stack>
      <Divider />
      <LabelFilters />
      <Stack className='typography-caption rounded-lg bg-bg-light-grey p-4'>
        {t('shared.projects.project.impactProjections.scenarioEditor.helper')}
      </Stack>
    </Stack>
  );
};

const ScenarioInfoLabel = () => {
  const { t } = useTranslation();
  const plots = usePlotsForProject().data.results;

  const [landPortionId, timeFrame] = useScenarioForm().watch(['landPortionId', 'timeFrame']);
  const isLandPortionProject = useIsLandPortionProject();

  const landPortion = isLandPortionProject
    ? t('shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.defaultLandPortion')
    : plots.find((plot) => plot.id === landPortionId)?.name;

  return (
    <Trans
      i18nKey={
        isLandPortionProject
          ? 'shared.projects.project.impactProjections.scenarioEditor.projectSettingsInfo'
          : 'shared.projects.project.impactProjections.scenarioEditor.plotSettingsInfo'
      }
      values={{ landPortion, timeFrame }}
      components={{
        strong: <span className='typography-body1Semibold' />,
      }}
    />
  );
};
