import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGScenarioChart } from '@/components/ChartsV1_2/CarbonStorageBGScenario';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { kgToTonneFormatter } from '@/components/ChartsV1_2/utils';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';

import { useBenchmarkFact } from '../../hooks/useBenchmarkFact';
import { useReportFact } from '../../hooks/useReportFact';
import { useScenarioForm } from '../../hooks/useScenarioForm';
import { NoData } from '../NoData';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const timeFrame = useScenarioForm().watch('timeFrame');

  const carbonBgHistoric = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );
  const cbgHistoricData = carbonBgHistoric?.value ?? [];

  const carbonBgUpliftPotential = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_uplift_potential_graph
      : R1FactType.r1_carbon_bg_uplift_potential_per_ha_graph,
  );
  const cbgPotentialData = carbonBgUpliftPotential?.value;

  const carbonBgLowBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'low' }] as PlotReportFactLabel[],
  );
  const cbgLowBenchmarkData = (carbonBgLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const carbonBgModerateBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const cbgModerateBenchmarkData = (carbonBgModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const carbonHighBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'high' }] as PlotReportFactLabel[],
  );
  const cbgHighBenchmarkData = (carbonHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const cbgBenchmarks = useBenchmarkFact(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    kgToTonneFormatter,
  );

  /**
   * We only null check for cbgPotentialData since cbgPotentialData is the primary source data of the graph
   * reference lines are displayed only when cbgPotentialData is rendered
   */
  const noChartData = !cbgPotentialData?.length;
  if (noChartData) {
    return <NoData />;
  }

  const chartData = [
    ...cbgHistoricData,
    ...cbgPotentialData,
    ...cbgLowBenchmarkData,
    ...cbgModerateBenchmarkData,
    ...cbgHighBenchmarkData,
  ];

  return (
    <Stack center data-testid='carbon-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.projects.project.impactProjections.carbon.labels.graphHeadline', { timeFrame })}
          popover={{
            title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
          }}
        />
        <CarbonStorageBGScenarioChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={cbgBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='carbon-storage-bg-potential-chart'
        />
      </Stack>
    </Stack>
  );
};
