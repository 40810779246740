import { R1FactType } from '@/api/rest/resources/types/fact';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { useBenchmarksForPlot, useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { assertIsDefined } from '@/utils/assert';

import { useIsLandPortionProject } from './useIsLandPortionProject';
import { useScenarioForm } from './useScenarioForm';

export const useBenchmarkFact = (
  benchmarkType: R1FactType,
  formatter?: (fact: GraphFact | undefined) => number | null | undefined,
) => {
  const scenarioForm = useScenarioForm();

  const [landPortionId] = scenarioForm.watch(['landPortionId']);
  assertIsDefined(landPortionId, 'landPortionId');

  const isLandPortionProject = useIsLandPortionProject();

  const projectFact = useBenchmarksForProject(
    benchmarkType,
    isLandPortionProject ? landPortionId : undefined,
    formatter,
  );

  const plotFact = useBenchmarksForPlot(benchmarkType, !isLandPortionProject ? landPortionId : null, formatter);

  return isLandPortionProject ? projectFact : plotFact;
};
