/* eslint-disable security/detect-object-injection */
import { Divider, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import {
  NCCardAnalysisType,
  NCCardSectionHeader,
  printLatestDate,
  useNCCardContext,
} from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { formatFactKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

import { DEFAULT_SCENARIO_INPUTS } from '../../constants';
import { TIME_FRAMES } from '../../hooks/constants';
import { ScenarioType } from '../../hooks/types';
import { getFormLabels, useReportFact } from '../../hooks/useReportFact';
import { useScenarioForm } from '../../hooks/useScenarioForm';

export const CarbonStatsTile = () => {
  return (
    <Stack className='gap-8 p-6 md:justify-center md:gap-4 md:p-8 md:pr-2' data-testid='stats-tile'>
      <HistoricBelowGroundCarbonStorage />
      <Divider />
      <ProjectedBelowGroundCarbonStorage />
    </Stack>
  );
};

const HistoricBelowGroundCarbonStorage = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const latestAnalysis = formatFactKilogramsToTonnes(
    useReportFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );

  const avgChange = formatFactKilogramsToTonnes(
    useReportFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_5y_uplift_total_avg_change
        : R1FactType.r1_carbon_bg_previous_5y_uplift_per_ha_avg_change,
    ),
  );

  const latestAnalysisDate = printLatestDate([latestAnalysis?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4' data-testid='historic-stats'>
      <NCCardSectionHeader
        title={t('shared.projects.project.impactProjections.carbon.labels.historicCarbonStorageBelowGround')}
        supplementaryInfo={`${t(
          'shared.projects.project.impactProjections.labels.latestAnalysis',
        )} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.latestAnalysis')}
          fact={latestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(latestAnalysis),
            variant: getNCChangeIconVariant(latestAnalysis),
          }}
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.latestAnalysis.body', {
              date: latestAnalysisDate,
            }),
            popoverTriggerProps: {
              'data-testid': 'historic-latest-analysis-tooltip',
            },
          }}
        />
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.fiveAverageYearlyChange')}
          fact={avgChange}
          capsuleIconProps={{
            icon: getNCChangeIcon(avgChange),
            variant: getNCChangeIconVariant(avgChange),
          }}
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.fiveAverageYearlyChange.body'),
            popoverTriggerProps: {
              'data-testid': 'historic-5yr-avg-change-tooltip',
            },
          }}
        />
      </Stack>
    </div>
  );
};

type TimePeriodFactMap = Record<keyof typeof TIME_FRAMES, Record<NCCardAnalysisType, R1FactType>>;

const latestAnalysisTimePeriodFactMap: TimePeriodFactMap = {
  '5': {
    total: R1FactType.r1_carbon_bg_5year_uplift_potential_total_change,
    average: R1FactType.r1_carbon_bg_5year_uplift_potential_per_ha_change,
  },
  '10': {
    total: R1FactType.r1_carbon_bg_10year_uplift_potential_total_change,
    average: R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_change,
  },
  '20': {
    total: R1FactType.r1_carbon_bg_20year_uplift_potential_total_change,
    average: R1FactType.r1_carbon_bg_20year_uplift_potential_per_ha_change,
  },
};

const avgChangeTimePeriodFactMap: TimePeriodFactMap = {
  '5': {
    total: R1FactType.r1_carbon_bg_5year_uplift_potential_total_avg_change,
    average: R1FactType.r1_carbon_bg_5year_uplift_potential_per_ha_avg_change,
  },
  '10': {
    total: R1FactType.r1_carbon_bg_10year_uplift_potential_total_avg_change,
    average: R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_avg_change,
  },
  '20': {
    total: R1FactType.r1_carbon_bg_20year_uplift_potential_total_avg_change,
    average: R1FactType.r1_carbon_bg_20year_uplift_potential_per_ha_avg_change,
  },
};

const percentChangeTimePeriodFactMap: TimePeriodFactMap = {
  '5': {
    total: R1FactType.r1_carbon_bg_5year_uplift_potential_total_pct_change,
    average: R1FactType.r1_carbon_bg_5year_uplift_potential_per_ha_pct_change,
  },
  '10': {
    total: R1FactType.r1_carbon_bg_10year_uplift_potential_total_pct_change,
    average: R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_pct_change,
  },
  '20': {
    total: R1FactType.r1_carbon_bg_20year_uplift_potential_total_pct_change,
    average: R1FactType.r1_carbon_bg_20year_uplift_potential_per_ha_pct_change,
  },
};

const ProjectedBelowGroundCarbonStorage = () => {
  const { t } = useTranslation();

  const [scenarioType, timeFrame] = useScenarioForm().watch(['scenarioType', 'timeFrame']);
  return (
    <>
      <NCCardSectionHeader
        title={t('shared.projects.project.impactProjections.carbon.labels.projectedCarbonStorageBelowGround', {
          timeFrame,
        })}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.body'),
        }}
      />
      <OptimizedBelowGroundCarbonStorage />
      {scenarioType === ScenarioType.customised && <CustomScenarioBelowGroundCarbonStorage />}
    </>
  );
};

const OptimizedBelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const timeFrame = useScenarioForm().watch('timeFrame');
  const timePeriod = timeFrame as keyof typeof TIME_FRAMES;

  const optimisedScenarioLabels = getFormLabels(DEFAULT_SCENARIO_INPUTS);

  const latestAnalysis = formatFactKilogramsToTonnes(
    useReportFact<number>(
      analysisType === 'total'
        ? latestAnalysisTimePeriodFactMap[timePeriod].total
        : latestAnalysisTimePeriodFactMap[timePeriod].average,
      optimisedScenarioLabels,
    ),
  );

  const avgChange = formatFactKilogramsToTonnes(
    useReportFact<number>(
      analysisType === 'total'
        ? avgChangeTimePeriodFactMap[timePeriod].total
        : avgChangeTimePeriodFactMap[timePeriod].average,
      optimisedScenarioLabels,
    ),
  );

  const percentChange = useReportFact<number>(
    analysisType === 'total'
      ? percentChangeTimePeriodFactMap[timePeriod].total
      : percentChangeTimePeriodFactMap[timePeriod].average,
    optimisedScenarioLabels,
  );

  const percentChangeDisplay = `${useDisplayNumber(percentChange?.value ?? 0, { signDisplay: 'always' })} %`;

  return (
    <Stack className='gap-8 lg:mt-4 lg:justify-center lg:gap-6' data-testid='optimised-stats'>
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.optimisedScenario')}
          fact={latestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(latestAnalysis),
            variant: getNCChangeIconVariant(latestAnalysis),
          }}
          trend={
            typeof percentChange?.value === 'number' ? (
              <CapsuleTrend variant={percentChange.value < 0 ? 'negative' : 'positive'}>
                {percentChangeDisplay}
              </CapsuleTrend>
            ) : (
              <span>-</span>
            )
          }
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.optimisedScenario.body'),
            popoverTriggerProps: {
              'data-testid': 'optimised-latest-analysis-tooltip',
            },
          }}
        />
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.estimatedAverageYearlyChange')}
          fact={avgChange}
          capsuleIconProps={{
            icon: getNCChangeIcon(avgChange),
            variant: getNCChangeIconVariant(avgChange),
          }}
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.estimatedAverageYearlyChange.body'),
            popoverTriggerProps: {
              'data-testid': 'optimised-avg-change-tooltip',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

const CustomScenarioBelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const timeFrame = useScenarioForm().watch('timeFrame');
  const timePeriod = timeFrame as keyof typeof TIME_FRAMES;

  const latestAnalysis = formatFactKilogramsToTonnes(
    useReportFact<number>(
      analysisType === 'total'
        ? latestAnalysisTimePeriodFactMap[timePeriod].total
        : latestAnalysisTimePeriodFactMap[timePeriod].average,
    ),
  );

  const avgChange = formatFactKilogramsToTonnes(
    useReportFact<number>(
      analysisType === 'total'
        ? avgChangeTimePeriodFactMap[timePeriod].total
        : avgChangeTimePeriodFactMap[timePeriod].average,
    ),
  );

  const percentChange = useReportFact<number>(
    analysisType === 'total'
      ? percentChangeTimePeriodFactMap[timePeriod].total
      : percentChangeTimePeriodFactMap[timePeriod].average,
  );

  const percentChangeDisplay = `${useDisplayNumber(percentChange?.value ?? 0, { signDisplay: 'always' })} %`;

  return (
    <Stack className='gap-8 lg:mt-4 lg:justify-center lg:gap-6' data-testid='customised-stats'>
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.customisedScenario')}
          fact={latestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(latestAnalysis),
            variant: getNCChangeIconVariant(latestAnalysis),
          }}
          trend={
            typeof percentChange?.value === 'number' ? (
              <CapsuleTrend variant={percentChange.value < 0 ? 'negative' : 'positive'}>
                {percentChangeDisplay}
              </CapsuleTrend>
            ) : (
              <span>-</span>
            )
          }
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.customisedScenario.body'),
            popoverTriggerProps: {
              'data-testid': 'customised-latest-analysis-tooltip',
            },
          }}
        />
        <NCFactCapsule
          label={t('shared.projects.project.impactProjections.labels.estimatedAverageYearlyChange')}
          fact={avgChange}
          capsuleIconProps={{
            icon: getNCChangeIcon(avgChange),
            variant: getNCChangeIconVariant(avgChange),
          }}
          infoPopoverProps={{
            body: t('shared.projects.project.impactProjections.explainers.estimatedAverageYearlyChange.body'),
            popoverTriggerProps: {
              'data-testid': 'customised-avg-change-tooltip',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
