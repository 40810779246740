import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { WaterHoldingScenarioChart } from '@/components/ChartsV1_2/WaterHoldingCapacityScenario';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';

import { useBenchmarkFact } from '../../hooks/useBenchmarkFact';
import { useReportFact } from '../../hooks/useReportFact';
import { useScenarioForm } from '../../hooks/useScenarioForm';
import { NoData } from '../NoData';

export const WaterGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const timeFrame = useScenarioForm().watch('timeFrame');

  const historicWaterAndSoilMoisture = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );
  const whcHistoricData = historicWaterAndSoilMoisture?.value ?? [];

  const waterHoldingCapacity10yearUpliftPotential = useReportFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
  );
  const whcData = waterHoldingCapacity10yearUpliftPotential?.value;

  const whcLowBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'low' }] as PlotReportFactLabel[],
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = useReportFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'high' }] as PlotReportFactLabel[],
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const whcBenchmarks = useBenchmarkFact(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
  );

  /**
   * We only null check for whcData since whcData is the primary source data of the graph
   * wpData and other data are reference lines that are displayed only when whcData is rendered
   */
  const noChartData = !whcData?.length;
  if (noChartData) {
    return <NoData />;
  }

  const chartData = [
    ...whcHistoricData,
    ...whcData,
    ...whcLowBenchmarkData,
    ...whcModerateBenchmarkData,
    ...whcHighBenchmarkData,
  ];

  return (
    <Stack center data-testid='water-uplift-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.projects.project.impactProjections.water.labels.graphHeadline', {
            timeFrame,
          })}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.upliftPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.upliftPotential.body'),
          }}
        />
        <WaterHoldingScenarioChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={whcBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='water-holding-capacity-chart'
        />
      </Stack>
    </Stack>
  );
};
