import { Stack } from '@landler/tw-component-library';

import { Form } from '@/components';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { CarbonCard } from './components/carbon/CarbonCard';
import { ScenarioEditor } from './components/scenario-editor/ScenarioEditor';
import { WaterCard } from './components/water/WaterCard';
import { DEFAULT_SCENARIO_INPUTS, DEFAULT_TIME_FRAME } from './constants';
import { useCreateScenarioForm } from './hooks/useScenarioForm';

export const ImpactProjectionsPage = () => {
  const projectId = useProjectId();

  const form = useCreateScenarioForm({
    landPortionId: projectId,
    timeFrame: DEFAULT_TIME_FRAME,
    ...DEFAULT_SCENARIO_INPUTS,
  });

  return (
    <Form {...form}>
      <Stack spacing={10} data-testid='impact-projections'>
        <ScenarioEditor />
        <CarbonCard />
        <WaterCard />
      </Stack>
    </Form>
  );
};
