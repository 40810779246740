import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { CarbonUpliftPotentialExplainer } from '@/components/NCAExplainersV1_2/CarbonUpliftPotentialExplainer';
import { NCCard, NCCard2SectionLayout, NCCardAnalysisTypeToggle, NCCardHeader } from '@/components/NCCardV1_2/NCCard';
import { useScreenSize } from '@/hooks/useScreenSize';

import { CarbonGraphTile } from './CarbonGraphTile';
import { CarbonStatsTile } from './CarbonStatsTile';

export const CarbonCard = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <NCCard explainer={<CarbonUpliftPotentialExplainer />} data-testid='carbon-impact-projections'>
      <NCCardHeader title={t('global.analysis.carbon')}>
        {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
      </NCCardHeader>
      <NCCard2SectionLayout className='md:min-h-[400px]'>
        <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
          {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />}
          <CarbonGraphTile />
        </Stack>
        <CarbonStatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};
